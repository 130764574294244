import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatIconRegistry} from '@angular/material/icon';
import {PSFThemesPlatformEnvironment, PSFThemesPlatformFeatures} from '@px/psf-themes/platform-env';
import {GoogleAnalyticsService} from './core/services/google-analytics.service';
import {WINDOW_TOKEN} from '@px/cdk/window';

@Component({
  selector: 'app-root',
  template: '<router-outlet />',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly translate = inject(TranslateService);
  private readonly matIcons = inject(MatIconRegistry);
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
  private readonly platform = inject(PSFThemesPlatformEnvironment);
  private readonly window = inject(WINDOW_TOKEN);
  private readonly useGqlApi = !!this.window.location.search.includes('useGqlApi');

  constructor() {
    if (this.platform.GTM_ENABLED) {
      this.googleAnalyticsService.init(this.platform.GTM_ID);
    }

    this.translate.use(this.translate.getBrowserLang());
    this.matIcons.setDefaultFontSetClass('icon');

    if (this.useGqlApi) {
      this.platform.FEATURES[PSFThemesPlatformFeatures.GQL_API_CA] = this.useGqlApi;
    }
  }
}
