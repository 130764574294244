import {inject, Injectable} from '@angular/core';
import {
  IPhotographerAuthBackendStrategy,
  IPhotographerAuthContext,
  IPhotographerForgotPasswordContext,
  IPhotographerOAuthContext,
  IPhotographerOAuthResponse,
  IPhotographerRefreshContext,
  IPhotographerRegisterContext,
} from '@px/photographer-auth/domain';
import {filter, map, Observable} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';
import {plainToInstance} from 'class-transformer';
import {ISession, Session} from '@px/shared/session-provider';

@Injectable()
export class AuthGqlService implements IPhotographerAuthBackendStrategy {
  private readonly apollo = inject(Apollo);

  private readonly authenticateCode = gql<
    {authenticateCode: IPhotographerOAuthResponse},
    {data: IPhotographerOAuthContext}
  >`
    mutation AuthenticateCode($data: AuthenticateCodeInput!) {
      authenticateCode(data: $data) {
        token
        refreshId
        expiration
      }
    }
  `;

  private readonly authenticateRefreshToken = gql<
    {authenticateRefreshToken: IPhotographerOAuthResponse},
    {data: IPhotographerRefreshContext}
  >`
    mutation AuthenticateRefreshToken($data: AuthenticateRefreshInput!) {
      authenticateRefreshToken(data: $data) {
        token
        refreshId
        expiration
      }
    }
  `;

  auth(authContext: IPhotographerAuthContext): Observable<ISession> {
    throw new Error('Method not implemented.');
  }

  forgotPassword(forgotPasswordContext: IPhotographerForgotPasswordContext): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  oAuth(oAuthContext: IPhotographerOAuthContext): Observable<ISession> {
    return this.apollo
      .mutate<{authenticateCode: IPhotographerOAuthResponse}, {data: IPhotographerOAuthContext}>({
        mutation: this.authenticateCode,
        variables: {data: oAuthContext},
        fetchPolicy: 'network-only',
        context: {
          canBeBatched: false,
        },
      })
      .pipe(
        filter(result => !!result.data?.authenticateCode),
        map(result => result.data?.authenticateCode as IPhotographerOAuthResponse),
        map(({token, expiration, refreshId}: IPhotographerOAuthResponse) => ({
          token,
          expiration,
          refreshId,
        })),
        map((result: IPhotographerOAuthResponse) => plainToInstance(Session, result))
      );
  }

  refresh(refreshContext: IPhotographerRefreshContext): Observable<ISession> {
    return this.apollo
      .mutate<{authenticateRefreshToken: IPhotographerOAuthResponse}, {data: IPhotographerRefreshContext}>({
        mutation: this.authenticateRefreshToken,
        variables: {data: refreshContext},
        fetchPolicy: 'network-only',
        context: {
          canBeBatched: false,
        },
      })
      .pipe(
        filter(result => {
          return !!result.data?.authenticateRefreshToken;
        }),
        map(result => result.data?.authenticateRefreshToken as IPhotographerOAuthResponse),
        map(
          ({token, expiration, refreshId}: IPhotographerOAuthResponse): IPhotographerOAuthResponse => ({
            token,
            expiration,
            refreshId,
          })
        ),
        map((result: IPhotographerOAuthResponse) => plainToInstance(Session, result))
      );
  }

  register(registerContext: IPhotographerRegisterContext): Observable<ISession> {
    throw new Error('Method not implemented.');
  }
}
