export interface IImageCollection {
  id: number;
  picId: number;
  name: string;
  originalFileName: string;
}

export class ImageCollection implements IImageCollection {
  originalFileName!: string;
  picId!: number;

  get id(): number {
    return this.picId;
  }

  get name(): string {
    return this.originalFileName;
  }
}
